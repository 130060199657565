import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Plugins
import { isEmpty } from 'lodash';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { createJob, getJobTimezoneList } from '../../actions';
import { getOrganizationList } from '../../../Organizations/actions';

// Components
import AddEditModal from '../../../Organizations/AddEditModal';

// Helpers
import validator from '@/utils/validator';

// Images
import ReactIconAdd from '@/assets/images/icon-add.svg?react';

// Styles
import 'react-datepicker/dist/react-datepicker.css';

const CAPTURE_QR_ENABLED = import.meta.env.VITE_CAPTURE_QR_ENABLED === '1';
const ORG_ITEMS_ORDER = 'name';
const ORG_ITEMS_PER_PAGE = 10000;

const AddNew = ({ history, onAddNewToggle }) => {
  const dispatch = useDispatch();
  const { timezones, jobTypes, requesting: jobRequesting } = useSelector((state) => state.jobs);
  const {
    studio: { id: studioId, time_zone: studioTimezone, reporting_code: studioReportingCode, feature_flags: studioFeatureFlags }
  } = useSelector((state) => state.login);
  const { organizations, requesting: organizationsRequesting } = useSelector((state) => state.organizations);
  const isStudioCaptureQrAllowed = CAPTURE_QR_ENABLED && studioFeatureFlags?.allow_capture_qr ? true : false;

  const [information, setInformation] = useState({
    logo_attachment: {},
    name: '',
    date: '',
    client_id: '',
    job_type: '',
    estimated_subjects_count: '',
    time_zone: studioTimezone,
    use_logo_for_themes: false,
    gallery_v4: true,
    biometrics_enabled: true,
    qr_enabled: false,
    reporting_code: undefined
  });
  const [showOrganizationAddModal, setShowOrganizationAddModal] = useState(false);

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          setInformation({
            ...information,
            logo_attachment: {
              content: reader.result,
              filename: name,
              content_type: type
            }
          });
        },
        false
      );

      reader.readAsDataURL(image);
    }
  };

  const handleLogoRemove = () => setInformation({ ...information, logo_url: null, logo_filename: null, logo_attachment: {}, use_logo_for_themes: false });

  const handleInputChange = (e, validation) => {
    const input = e.target;
    const valid = validator({ ...validation, value: input.value });

    input.classList[`${valid ? 'remove' : 'add'}`]('input--invalid');
    setInformation({ ...information, [input.name]: valid ? input.value : information[input.name] });
  };

  const handleSelectChange = (select) => setInformation({ ...information, [select.name]: select.value });

  const handleOrganizationListRefresh = () =>
    dispatch(
      getOrganizationList({
        studioId,
        searchParams: { order: ORG_ITEMS_ORDER, per_page: ORG_ITEMS_PER_PAGE }
      })
    );
  const handleOrganizationAdd = () => setShowOrganizationAddModal(true);
  const handleOrganizationAddModalClose = () => setShowOrganizationAddModal(false);

  const handleSwitchChange = (e) => setInformation({ ...information, [e.target.name]: e.target.checked });

  const handleDateChange = (date) => setInformation({ ...information, date });

  const handleCreate = () => {
    const isQrEnabled = information.biometrics_enabled ? false : true;
    dispatch(createJob({ studioId, ...information, qr_enabled: isQrEnabled }, ({ data }) => history.push(`/jobs/${data.id}/dashboard`)));
  };

  useEffect(() => {
    if (!timezones.length) {
      dispatch(getJobTimezoneList());
    }

    dispatch(getOrganizationList({ studioId, searchParams: { order: ORG_ITEMS_ORDER, per_page: ORG_ITEMS_PER_PAGE } }));
  }, []);

  return (
    <>
      <aside className="modal animation">
        <div className="modal__box modal__box--secondary">
          <button className="button button--action modal__close" name="button" type="button" onClick={onAddNewToggle}>
            <i className="icon-close"></i>
          </button>
          <main className="flex modal__content">
            <section className="basis-2/6 md:basis-full modal__content-section modal__content-section--dark">
              <h3 className="text-left">Create a Job</h3>
              <h6>Job Logo (Optional)</h6>
              <p className="text-body-sm">
                Job logos can be a great way to not only give your gallery some personality, but also help differentiate jobs in your customers' gallery lists
                if they have purchased from multiple galleries. Learn more about job logo best practices
              </p>
              <a
                className="text-body-sm"
                href="https://support.photoday.io/en/articles/4427775-best-practices-for-custom-job-logos"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about job logo best practices
              </a>
              <div className="flex justify-center">
                <figure className="job-add__logo">
                  <label htmlFor="job-logo" className="flex items-center justify-center job-add__logo-caption">
                    {information?.logo_attachment?.content ? (
                      <img className="job-add__logo-image animate" src={information.logo_attachment.content} alt={information.logo_attachment.filename} />
                    ) : (
                      <ReactIconAdd className="job-add__icon" />
                    )}
                  </label>
                  <input id="job-logo" className="hidden" type="file" accept="image/png" onChange={handleLogoUpload} />
                </figure>
                <button
                  className={`button button--block button--danger button--medium animate ${
                    information.logo_attachment?.content || information.logo_url ? '' : 'disabled'
                  }`}
                  type="button"
                  name="remove"
                  onClick={handleLogoRemove}
                >
                  Remove
                </button>
                {information.logo_attachment?.content && (
                  <fieldset className="job-add__logo-message">
                    <h5 className="flex items-center justify-between">
                      <span>Use logo on themes</span>
                      <input
                        id="use_logo_for_themes"
                        className="hidden"
                        name="use_logo_for_themes"
                        type="checkbox"
                        checked={information.use_logo_for_themes}
                        onChange={handleSwitchChange}
                      />
                      <label htmlFor="use_logo_for_themes" className="label-switch label-switch--small" />
                    </h5>
                    <p className="text-body-sm">When toggled on, the job logo may appear on Memory Mates with some labs.</p>
                  </fieldset>
                )}
              </div>
            </section>
            <section className="basis-8/12 md:basis-full modal__content-section">
              <h3 className="text-left">Job Information</h3>
              <form className="pb-40">
                <fieldset>
                  <div className="flex flex-row">
                    <div className="basis-6/12 sm:basis-full">
                      <label>Job Name</label>
                      <input type="text" name="name" maxLength="50" value={information.name} onChange={handleInputChange} />
                    </div>
                    <div className="basis-6/12 sm:basis-full">
                      <label>Job Category</label>
                      <Select
                        className="select"
                        classNamePrefix="select"
                        name="job_type"
                        options={jobTypes}
                        value={jobTypes.filter((type) => type.value === information.job_type)}
                        onChange={(value) => handleSelectChange({ ...value, name: 'job_type' })}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <label>Organization</label>
                  <div className="button-group job-add__select">
                    <div className="flex nowrap">
                      <Select
                        className="select"
                        classNamePrefix="select"
                        isLoading={organizationsRequesting}
                        value={organizations
                          .filter((organization) => organization.id === information.client_id)
                          .map((organization) => ({ value: organization.id, label: organization.name }))}
                        options={organizations.map((organization) => ({ value: organization.id, label: organization.name }))}
                        onChange={(value) => handleSelectChange({ ...value, name: 'client_id' })}
                      />
                    </div>
                    <button className="button button--clean" type="button" onClick={handleOrganizationListRefresh}>
                      <i className="icon-refresh"></i>
                    </button>
                    <button className="button button--small button--lean" name="add" type="button" onClick={handleOrganizationAdd}>
                      <i className="icon-add"></i>
                    </button>
                  </div>
                </fieldset>
                <fieldset>
                  <label>Picture Date</label>
                  <DatePicker
                    className="input--date"
                    name="date"
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    isClearable={true}
                    strictParsing
                    autoComplete="off"
                    selected={information.date}
                    onChange={handleDateChange}
                  />
                </fieldset>
                <fieldset>
                  <label>Estimated # Being Photographed (Optional)</label>
                  <input
                    type="number"
                    name="estimated_subjects_count"
                    min={1}
                    max={10000}
                    value={information.estimated_subjects_count}
                    placeholder="# Being Photographed"
                    onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 5 })}
                  />
                </fieldset>
                {studioReportingCode && (
                  <fieldset>
                    <label>Job Reporting Code</label>
                    <input
                      type="text"
                      name="reporting_code"
                      value={information.reporting_code ?? ''}
                      maxLength={40}
                      onChange={(e) => handleInputChange(e, { name: 'maxLength', rule: 100 })}
                    />
                  </fieldset>
                )}
                <hr />
                <fieldset>
                  <label>Time Zone</label>
                  <p className="text-body-sm m-0">The job's Dashboard, Insights, and History information will use this time zone.</p>
                  <Select
                    className="select select--nomargin"
                    classNamePrefix="select"
                    name="time_zone"
                    value={{ value: information.time_zone, label: information.time_zone }}
                    options={timezones?.length && timezones.map(({ name }) => ({ value: name, label: name }))}
                    onChange={(value) => handleSelectChange({ ...value, name: 'time_zone' })}
                  />
                </fieldset>
                {isStudioCaptureQrAllowed && (
                  <>
                    <hr />
                    <aside className="panel panel--round panel--secondary">
                      <div className="flex justify-between">
                        <h5 className="flex items-center gap-1">Enable FaceFind</h5>
                        <input
                          id="biometrics_enabled"
                          className="hidden"
                          name="biometrics_enabled"
                          type="checkbox"
                          checked={information.biometrics_enabled}
                          onChange={handleSwitchChange}
                        />
                        <label htmlFor="biometrics_enabled" className="label-switch label-switch--small" />
                      </div>
                      <p className="text-body-sm">
                        Let FaceFind take care of matching subjects to streamline your workflow and create an amazing shopping experience for your customers.{' '}
                        <a href="https://support.photoday.io/en/articles/5580626-photoday-s-facefind-feature" target="_blank" rel="noopener noreferrer">
                          Learn More
                        </a>
                      </p>
                      <p className="text-body-sm">
                        <strong className="text-error-500">Warning!</strong> This setting cannot be changed after a job is created.
                      </p>
                      <p className="text-body-sm">
                        FaceFind uses biometric technology to match photos. Where you conduct business may limit the use of FaceFind.{' '}
                        <a href="https://www.photoday.com/biometrics" target="_blank" rel="noopener noreferrer">
                          Learn More
                        </a>
                      </p>
                    </aside>
                  </>
                )}
                <aside className="panel panel--round panel--secondary">
                  <h5>PhotoDay Galleries</h5>
                  <p className="text-body-sm m-0">
                    Your customers can access their photos at{' '}
                    <a href="https://my.photoday.com" target="_blank" rel="noopener noreferrer">
                      my.photoday.com
                    </a>
                    .
                  </p>
                </aside>
              </form>
              <footer className="modal__footer modal__footer--fixed flex justify-end gap-2.5">
                <button className="button button--outline" type="button" name="cancel" onClick={onAddNewToggle}>
                  Cancel
                </button>
                <button
                  className="button"
                  name="button"
                  type="submit"
                  data-loading={jobRequesting}
                  disabled={
                    jobRequesting ||
                    !(
                      information.name &&
                      information.client_id &&
                      information.job_type &&
                      information.date &&
                      (!isEmpty(studioReportingCode) ? !isEmpty(information.reporting_code) : true)
                    )
                  }
                  onClick={handleCreate}
                >
                  Create Job
                </button>
              </footer>
            </section>
          </main>
        </div>
      </aside>

      {/* Add New Organization Modal */}
      {showOrganizationAddModal && <AddEditModal studioId={studioId} onModalClose={handleOrganizationAddModalClose} />}
    </>
  );
};

AddNew.propTypes = {
  history: PropTypes.object.isRequired,
  showAddNew: PropTypes.bool.isRequired
};

AddNew.defaultProps = {
  history: {},
  showAddNew: false
};

export default AddNew;
