import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import OfferForm from './OfferForm';

const mapStateToProps = (state) => {
  const freebieType = formValueSelector('OfferForm')(state, 'freebie_type');

  return { freebieType: (freebieType && freebieType.value) || freebieType };
};

class CreateOrEditOfferModal extends Component {
  static propTypes = {
    offer: PropTypes.shape({
      id: PropTypes.string,
      offer_type: PropTypes.string
    }).isRequired,
    offerType: PropTypes.string.isRequired,
    pricesheets: PropTypes.array,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  static defaultProps = {
    offer: {}
  };

  constructor(props) {
    super(props);

    const {
      offer: { id, offer_type },
      offerType
    } = this.props;

    let mode = id ? 'edit' : 'create';
    let step = OfferForm.STEPS.CHOOSE_TYPE;

    if (mode === 'edit') {
      if (offer_type === 'freebie') {
        step = OfferForm.STEPS.GIVEAWAY_MAIN;
      } else if (offer_type === 'shipping') {
        step = OfferForm.STEPS.FREE_SHIPPING_MAIN;
      } else {
        if (offerType === 'credit_sheets') {
          step = OfferForm.STEPS.CREDIT_SHEETS;
        } else {
          step = OfferForm.STEPS.DISCOUNT_MAIN;
        }
      }
    }

    this.state = { mode, step };
  }

  save = (values) => {
    const { onSave, offer, offerType } = this.props;
    const { shipping_type } = offer;

    const newValues = {
      ...values,
      offer_type: values.offer_type || offerType,
      price_sheet_id: (values.price_sheet_id && values.price_sheet_id.value) || values.price_sheet_id,
      shipping_type: typeof values.sw2 !== 'undefined' ? (values.sw2 ? 'free' : 'paid') : shipping_type || 'paid'
    };

    onSave && onSave(newValues);
  };

  cancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  handleChangeStep = (step) => {
    this.setState({ ...this.state, step });
  };

  goBack = () => {
    const prevStep = this.prevStep;
    prevStep && this.handleChangeStep(prevStep);
  };

  get modalTitle() {
    const { mode, step } = this.state;

    switch (step) {
      case OfferForm.STEPS.DISCOUNT_MAIN:
        return mode === 'create' ? 'Create Discount Offer' : 'Update Discount Offer';

      case OfferForm.STEPS.DISCOUNT_OPTIONS:
        return 'Discount Offer Options';

      case OfferForm.STEPS.DISCOUNT_FREE_SHIPPING:
        return 'Discount Offer Options';

      case OfferForm.STEPS.GIVEAWAY_MAIN:
        return mode === 'create' ? 'Create Giveaway Offer' : 'Update Giveaway Offer';

      case OfferForm.STEPS.GIVEAWAY_OPTIONS:
        return 'Giveaway Offer Options';

      case OfferForm.STEPS.BOGO_REQUIRED_OPTIONS:
        return mode === 'create' ? 'Create BOGO Offer' : 'Update BOGO Offer';

      case OfferForm.STEPS.BOGO_FREE_OPTIONS:
        return mode === 'create' ? 'Create BOGO Offer' : 'Update BOGO Offer';

      case OfferForm.STEPS.GIVEAWAY_FREE_SHIPPING:
        return 'Giveaway Offer Options';

      case OfferForm.STEPS.FREE_SHIPPING_MAIN:
        return mode === 'create' ? 'Create Free Shipping Offer' : 'Update Free Shipping Offer';

      case OfferForm.STEPS.CREDIT_SHEETS:
        return mode === 'create' ? 'Create a Credit Sheet' : 'Edit a Credit Sheet';

      case OfferForm.STEPS.CHOOSE_TYPE:
      default:
        return 'Select Offer Type';
    }
  }

  get prevStep() {
    const { freebieType } = this.props;
    const bogo = freebieType === 'bogo';
    const { mode, step } = this.state;

    switch (step) {
      case OfferForm.STEPS.DISCOUNT_MAIN:
        return mode === 'create' ? OfferForm.STEPS.CHOOSE_TYPE : null;

      case OfferForm.STEPS.DISCOUNT_OPTIONS:
        return OfferForm.STEPS.DISCOUNT_MAIN;

      case OfferForm.STEPS.DISCOUNT_FREE_SHIPPING:
        return OfferForm.STEPS.DISCOUNT_MAIN;

      case OfferForm.STEPS.GIVEAWAY_MAIN:
        return mode === 'create' ? OfferForm.STEPS.CHOOSE_TYPE : null;

      case OfferForm.STEPS.GIVEAWAY_OPTIONS:
        return OfferForm.STEPS.GIVEAWAY_MAIN;

      case OfferForm.STEPS.GIVEAWAY_FREE_SHIPPING:
        return bogo ? OfferForm.STEPS.BOGO_FREE_OPTIONS : OfferForm.STEPS.GIVEAWAY_OPTIONS;

      case OfferForm.STEPS.BOGO_FREE_OPTIONS:
        return OfferForm.STEPS.BOGO_REQUIRED_OPTIONS;

      case OfferForm.STEPS.BOGO_REQUIRED_OPTIONS:
        return OfferForm.STEPS.GIVEAWAY_MAIN;

      case OfferForm.STEPS.FREE_SHIPPING_MAIN:
        return mode === 'create' ? OfferForm.STEPS.CHOOSE_TYPE : null;

      case OfferForm.STEPS.CHOOSE_TYPE:
      default:
        return null;
    }
  }

  render() {
    const { offer, pricesheets, offerType } = this.props;
    const { mode, step } = this.state;
    const prevStep = this.prevStep;

    return (
      <aside className="modal animate">
        <div className="modal__box">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={this.cancel}>
              <i className="icon-close"></i>
            </button>
            {prevStep && prevStep !== 'ChooseType' && (
              <button className="storefront__offers__goback button button--outline" type="button" onClick={this.goBack}>
                Back
              </button>
            )}
            <h3 className="font-bold text-center mb-10">{this.modalTitle}</h3>
          </header>
          <main className="modal__content">
            <OfferForm
              mode={mode}
              step={step}
              offer={offer}
              offerTypeSelected={offerType}
              pricesheets={pricesheets}
              onChangeStep={this.handleChangeStep}
              onSubmit={this.save}
            />
          </main>
          <footer className="text-center modal__footer">
            <button className="button button--outline button--large" onClick={this.cancel}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>
    );
  }
}

export default connect(mapStateToProps)(CreateOrEditOfferModal);
