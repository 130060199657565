import React, { useState, useEffect } from 'react';
import { Form, reduxForm } from 'redux-form';
import { Input, ReactSelect, Textarea } from '@/components/Shared/Forms';

const RegisterForm = ({ handleSubmit, initialValues }) => {
  const [hearAboutUsSpecify, setHearAboutUsSpecify] = useState(false);

  useEffect(() => {
    if (initialValues?.meta?.hear_about_us_specify) {
      setHearAboutUsSpecify(true);
    }
  }, [initialValues]);

  const hearAboutUsOptions = [
    { label: 'Google', value: 'google' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'My Lab (please specify)', value: 'my_lab' },
    { label: 'Tradeshow (please specify)', value: 'tradeshow' },
    { label: 'Other (please specify)', value: 'other' }
  ];

  const photographyTypes = [
    { label: 'Youth Sports', value: 'youth_sports' },
    { label: 'High School Sports', value: 'high_school_sports' },
    { label: 'Dance Schools', value: 'dance_schools' },
    { label: 'Preschools', value: 'preschools' },
    { label: 'Elementary School', value: 'elementary_school' },
    { label: 'Underclass', value: 'underclass' },
    { label: 'Seniors', value: 'seniors' },
    { label: 'Graduation', value: 'graduation' },
    { label: 'Portraits', value: 'portraits' },
    { label: 'Weddings', value: 'weddings' },
    { label: 'Events', value: 'events' },
    { label: 'Other', value: 'Other' }
  ];

  const subjectsPerYear = [
    { label: 'Less than 1,000', value: 'Less than 1,000' },
    { label: '1000+', value: '1000+' },
    { label: '5000+', value: '5000+' },
    { label: '10,000+', value: '10,000+' },
    { label: '25,000+', value: '25,000+' },
    { label: '50,000+', value: '50,000+' },
    { label: '100,000+', value: '100,000+' }
  ];

  const yearsInBusiness = [
    { label: 'Just Getting Started', value: 'just_getting_started' },
    { label: 'Less Than 2 Years', value: 'less_than_2_years' },
    { label: '2-5 Years', value: '2-5_years' },
    { label: '6-9 Years', value: '6-9_years' },
    { label: '10-14 Years', value: '10-14_years' },
    { label: '15+ Years', value: '15+_years' }
  ];

  const nextPictureDay = [
    { label: 'Just exploring', value: 'just_exploring' },
    { label: 'It’s already happened', value: 'its_already_happened' },
    { label: 'This week', value: 'this_week' },
    { label: 'This month', value: 'this_month' },
    { label: 'Sometime this year', value: 'sometime_this_year' }
  ];

  return (
    <Form onSubmit={handleSubmit} className="sign-up-form">
      <>
        <div className="flex flex-row">
          <div className="basis-6/12">
            <Input label="First Name" name="user.first_name" required autoComplete="given-name" />
          </div>
          <div className="basis-6/12">
            <Input label="Last Name" name="user.last_name" required autoComplete="family-name" />
          </div>
        </div>
        <div className="flex mb-5">
          <div className="basis-full">
            <Input label="Email Address" required email name="user.email" />
          </div>
        </div>
        <div className="flex mb-5">
          <div className="basis-full">
            <label>Where did you hear about us?</label>
            <ReactSelect
              placeholder="Select..."
              name="meta.hear_about_us"
              required
              options={hearAboutUsOptions}
              onChange={({ label }) => setHearAboutUsSpecify(label.toLowerCase().includes('specify') ? true : false)}
            />
          </div>
        </div>
        {hearAboutUsSpecify && (
          <div className="flex animate">
            <div className="basis-full">
              <Textarea label="Please specify" name="meta.hear_about_us_specify" rows="3" />
            </div>
          </div>
        )}
        <div className="flex mb-5">
          <div className="basis-full">
            <label>What type of photography do you do?</label>
            <ReactSelect placeholder="Choose all that apply" name="meta.types_of_photography" required noEmptyArray multi={true} options={photographyTypes} />
          </div>
        </div>
        <div className="flex mb-5">
          <div className="basis-full">
            <label>How many subjects do you photograph per year?</label>
            <ReactSelect placeholder="Select..." name="meta.subjects_per_year" required options={subjectsPerYear} />
          </div>
        </div>
        <div className="flex mb-5">
          <div className="basis-full">
            <label>How many years have you been in business?</label>
            <ReactSelect placeholder="Select..." name="meta.years_in_business" required options={yearsInBusiness} />
          </div>
        </div>
        <div className="flex mb-5">
          <div className="basis-full">
            <label>When is your next picture day job?</label>
            <ReactSelect placeholder="Select..." name="meta.next_picture_day" required options={nextPictureDay} />
          </div>
        </div>
      </>
      <div className="flex">
        <div className="basis-full text-center">
          <button className="button button--block login-btn text-center" type="submit">
            Next
          </button>
        </div>
      </div>
    </Form>
  );
};

export default reduxForm({ form: 'RegisterForm', destroyOnUnmount: false })(RegisterForm);
