import { FC, useState, ChangeEvent, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrganization, updateOrganization } from '../actions';
import { getMyAccountStudioRequest } from '../../Settings/actions';

// Plugins
import { Tooltip } from 'react-tippy';
import { useFlags } from 'flagsmith/react';

// Components
import AddEditModal from '../AddEditModal';
import LogoErrorModal from './LogoErrorModal';
import DeleteModal from '../DeleteModal';
import AddEditContactModal from './AddEditContactModal';
import DeleteContactModal from './DeleteContactModal';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Hooks
import { useCopyToClipboard } from '@/hooks';

// Helpers
import { normalizePhone } from '@/utils/formatters';

// Images
import imageOrgGraph from '@/assets/images/illustration-org-page.svg';
import ReactIconWarn from '@/assets/images/icon-mark.svg?react';

// Types
import { Organization } from '@/types';

const MY_PHOTODAY_ORG_PAGE_URL = import.meta.env.VITE_MY_PHOTODAY_ORG_PAGE_URL;

const Details: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Flags
  const flags = useFlags(['group_galleries_on_org_page']);
  const isGroupGalleriesFlagEnabled = flags?.group_galleries_on_org_page?.enabled;

  const params: { organizationId: string } = useParams();
  const { organizationId } = params;

  const { organization, requesting } = useSelector((state: any) => state.organizations);
  const isPageLoading = organization?.id !== organizationId && requesting;

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio } = useSelector((state: any) => state.users);
  const { id: studioId, studio_slug: studioSlug } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const [, copyToClipboard] = useCopyToClipboard();

  const [organizationInfo, setOrganizationInfo] = useState<Partial<Organization>>();
  const [logoErrorMessage, setLogoErrorMessage] = useState<string>('');

  const [showLogoErrorModal, setShowLogoErrorModal] = useState<boolean>(false);
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [showAddEditContactModal, setShowAddEditContactModal] = useState<boolean>(false);
  const [showDeleteContactModal, setShowDeleteContactModal] = useState<boolean>(false);

  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);

  const studioPageUrl = `${MY_PHOTODAY_ORG_PAGE_URL}${studioSlug}/`;
  const didOrganizationChange =
    organization?.org_page_enabled === organizationInfo?.org_page_enabled &&
    organization?.org_page_slug === organizationInfo?.org_page_slug &&
    organization?.org_page_access_code === organizationInfo?.org_page_access_code &&
    organization?.org_page_secure_group_galleries === organizationInfo?.org_page_secure_group_galleries &&
    organization?.auto_show_new_jobs_on_org_page === organizationInfo?.auto_show_new_jobs_on_org_page &&
    organization?.auto_show_draft_on_org_page === organizationInfo?.auto_show_draft_on_org_page &&
    organization?.auto_show_expired_on_org_page === organizationInfo?.auto_show_expired_on_org_page;

  // UI Handlers
  const handleOrganizationEdit = (): void => setShowAddEditModal(true);
  const handleOrganizationDelete = (): void => setShowDeleteModal(true);

  const handleTextCopy = (text?: string): Promise<boolean> => copyToClipboard(text ?? '');

  const handleContactAdd = (): void => {
    setSelectedContactId(null);
    setShowAddEditContactModal(true);
  };
  const handleContactEdit = (contactId: string): void => {
    setSelectedContactId(contactId);
    setShowAddEditContactModal(true);
  };
  const handleContactDelete = (contactId: string): void => {
    setShowDeleteContactModal(true);
    setSelectedContactId(contactId);
  };

  const handleOrgInfoChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>): void => {
    const targetName = target.name;
    const targetType = target.type;

    let targetValue;

    switch (targetType) {
      case 'checkbox':
        targetValue = target.checked;
        break;
      case 'radio':
        targetValue = JSON.parse(target.value); // coerce input value string to boolean
        break;
      default:
        targetValue = target.value.replace(/[^a-zA-Z0-9-]/g, '');
    }

    setOrganizationInfo((prevState?: Partial<Organization>) => ({ ...prevState, [targetName]: targetValue }));
  };

  const handleOrgLogoChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    const image = target.files?.[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();

      reader.onload = () => {
        const minimumDimension = 600;
        const image = new Image();
        image.src = reader.result as string;
        image.onload = () => {
          if (image.width < minimumDimension || image.height < minimumDimension) {
            setShowLogoErrorModal(true);
            return;
          } else {
            dispatch(
              updateOrganization(
                { organizationId, updatedOrganizationInfo: { logo_attachment: { content: reader.result as string, filename: name, content_type: type } } },
                null,
                (error: any) => {
                  const { data } = error?.response || {};
                  const message = data?.error_localized || data?.error || '';

                  setShowLogoErrorModal(true);
                  setLogoErrorMessage(message);
                }
              )
            );
          }
        };
      };

      reader.readAsDataURL(image);
    }
  };

  const handleOrgLogoRemove = (): void => {
    if (organization?.logo_url) {
      dispatch(updateOrganization({ organizationId, updatedOrganizationInfo: { logo_attachment: {} } }));
    }
  };

  const handleLogoErrorClose = (): void => {
    setLogoErrorMessage('');
    setShowLogoErrorModal(false);
  };

  const handleAddEditModalClose = (): void => setShowAddEditModal(false);
  const handleDeleteModalClose = (): void => setShowDeleteModal(false);
  const handleOrganizationDeleteConfirm = (): void => history.goBack();

  const handleAddEditContactModalClose = (): void => setShowAddEditContactModal(false);
  const handleDeleteContactModalClose = (): void => setShowDeleteContactModal(false);

  const handleOrgPageSave = (): void => {
    const noEmptyStringOrganizationInfo: Partial<Organization> = Object.fromEntries(
      Object.entries(organizationInfo ?? {}).map(([key, value]) => [key, value === '' ? null : value])
    );

    dispatch(updateOrganization({ organizationId, updatedOrganizationInfo: noEmptyStringOrganizationInfo }));
  };

  useEffect(() => {
    dispatch(getOrganization({ organizationId }));
    dispatch(getMyAccountStudioRequest({ studioId }));
  }, []);

  useEffect(() => {
    if (organization.id) {
      setOrganizationInfo({
        org_page_enabled: organization.org_page_enabled,
        org_page_slug: organization.org_page_slug,
        org_page_access_code: organization.org_page_access_code,
        org_page_secure_group_galleries: organization.org_page_secure_group_galleries,
        auto_show_new_jobs_on_org_page: organization.auto_show_new_jobs_on_org_page,
        auto_show_draft_on_org_page: organization.auto_show_draft_on_org_page,
        auto_show_expired_on_org_page: organization.auto_show_expired_on_org_page
      });
    }
  }, [organization]);

  return (
    <>
      <header className="organizations__header">
        <Link to="/organizations" className="flex items-center grow-0 gap-1">
          <i className="icon-back"></i>
          <h5 className="m-0">Back</h5>
        </Link>
      </header>
      <main className="flex flex-nowrap gap-5">
        <aside className="basis-3/12">
          {isPageLoading ? (
            <GridLoader rows={3} columns={1} gap={20} minHeight={100} />
          ) : (
            <>
              <div className="panel panel--high mt-0">
                <button className="button button--outline button--small absolute top-2.5 right-2.5" name="edit" type="button" onClick={handleOrganizationEdit}>
                  <i className="icon-edit" />
                </button>
                <hgroup className="mb-7">
                  <h4>{organization.name}</h4>
                  {organization.reporting_code && <h6 className="italic truncate">({organization.reporting_code})</h6>}
                </hgroup>
                <p className="text-body-sm">{organization.notes || 'Add notes here'}</p>
              </div>

              <div>
                <h4>Organization Logo (Optional)</h4>
                <p>Organization logos are a great way to give your Organization page some personality.</p>
                <a href="https://support.photoday.io/en/articles/4427775-best-practices-for-custom-logos" target="_blank" rel="noopener noreferrer">
                  Learn more about organization logo best practices
                </a>
                <figure className="panel">
                  <div className={`organizations__logo ${requesting ? 'organizations__logo--processing' : ''}`}>
                    {organization?.logo_url ? (
                      <img className="organizations__image animate" src={organization?.logo_url} alt={organization.name} />
                    ) : (
                      <i className="icon-logo-placeholder animate" />
                    )}
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <label className="button button--outline" htmlFor="logo">
                      <input id="logo" className="hidden" type="file" accept="image/*" onChange={handleOrgLogoChange} />
                      {organization?.logo_url ? 'Replace' : 'Upload'}
                    </label>
                    <button
                      className="button button--danger-outline"
                      type="button"
                      name="remove"
                      data-loading={requesting}
                      disabled={!organization?.logo_url}
                      onClick={handleOrgLogoRemove}
                    >
                      Remove
                    </button>
                  </div>
                </figure>
              </div>

              <div>
                <h4>Delete Organization</h4>
                <p className="text-body-sm">
                  <strong className="text-error-500">WARNING!</strong> This is action cannot be undone. If you’d like to delete this organization, you may do so{' '}
                  <button className="button button--link" name="delete" type="button" onClick={handleOrganizationDelete}>
                    here
                  </button>
                  .
                </p>
              </div>
            </>
          )}
        </aside>
        <section className="basis-9/12">
          {isPageLoading ? (
            <GridLoader rows={3} columns={1} gap={20} minHeight={200} />
          ) : (
            <>
              <header className="flex items-center justify-between mb-2.5">
                <h4 className="m-0">Contact Information</h4>
                <button className="button" name="add" type="button" onClick={handleContactAdd}>
                  Add Contact
                </button>
              </header>
              <article className="table-box table-box--height-short">
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {organization.contacts?.map((item: any) => (
                      <tr key={item.id}>
                        <td data-header="Name">
                          {item.first_name} {item.last_name}
                        </td>
                        <td data-header="Role">{item.title}</td>
                        <td data-header="Email">{item.email}</td>
                        <td data-header="Phone Number">{normalizePhone(item.phone)}</td>
                        <td data-header="Actions">
                          <Tooltip {...{ title: 'Edit Contact' }}>
                            <button className="button button--clean" name="edit" type="button" onClick={() => handleContactEdit(item.id)}>
                              <i className="icon-edit" />
                            </button>
                          </Tooltip>
                          <Tooltip {...{ title: 'Delete Contact' }}>
                            <button className="button button--clean" name="delete" type="button" onClick={() => handleContactDelete(item.id)}>
                              <i className="icon-trash" />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
              <aside className="flex justify-between flex-nowrap gap-5 panel panel--light-green">
                <hgroup className="basis-9/12">
                  <h1 className="text-display-xs font-bold text-primary-purple-700">
                    A single link for Organizations<br></br> to access all their galleries
                  </h1>
                  <h5 className="text--normal m-0">
                    Organization Pages allow you to set up and share a single link to all your galleries related to an organization. Never worry about tracking
                    down multiple access codes again.
                  </h5>
                </hgroup>
                <img className="mb-0" src={imageOrgGraph} width="213" alt="Single place" />
              </aside>
              {!studioSlug && (
                <aside className="flex justify-between flex-nowrap panel panel--light-warning">
                  <div className="flex flex-nowrap items-center gap-2.5">
                    <ReactIconWarn className="organizations__icon" />
                    <p className="m-0 text-body-lg">Set your Studio Identifier to start using Organization Pages.</p>
                  </div>
                  <Link to="/user/my-studio/information" className="button">
                    Set Studio Identifier
                  </Link>
                </aside>
              )}
              <form className={`panel ${studioSlug ? '' : 'disabled'}`}>
                <fieldset className="flex items-center justify-between mb-5">
                  <h2 className="text-headline-sm">Enable Organization Page</h2>
                  <input
                    id="orgPageEnable"
                    className="hidden"
                    name="org_page_enabled"
                    type="checkbox"
                    checked={!!organizationInfo?.org_page_enabled}
                    onChange={handleOrgInfoChange}
                  />
                  <label className="label-switch label-switch--small" htmlFor="orgPageEnable" />
                </fieldset>
                <fieldset className={organizationInfo?.org_page_enabled ? '' : 'disabled'}>
                  <div className="flex flex-nowrap gap-10">
                    <div className="basis-6/12 mb-5">
                      <p>
                        Now available for all gallery types.
                        <a href="https://support.photoday.io/en/articles/9141406-organization-pages" target="_blank" rel="noopener noreferrer">
                          Learn More
                        </a>
                        .
                      </p>
                      <label htmlFor="slug">
                        Organization URL{' '}
                        <Tooltip {...{ title: 'Customize the URL for your Organization. The URL must be justify-between 3-24 characters.' }}>
                          <i className="icon-question-mark"></i>
                        </Tooltip>
                      </label>
                      <div className="flex flex-nowrap items-center relative">
                        <input type="text" value={studioPageUrl} readOnly={true} />
                        <input
                          id="slug"
                          type="text"
                          name="org_page_slug"
                          placeholder="my-org-page"
                          value={organizationInfo?.org_page_slug ?? ''}
                          maxLength={20}
                          onChange={handleOrgInfoChange}
                        />
                        <button
                          className="button button--icon"
                          name="copy"
                          type="button"
                          disabled={!organizationInfo?.org_page_slug}
                          onClick={() => handleTextCopy(`${studioPageUrl}${organizationInfo?.org_page_slug}`)}
                        >
                          <i className="icon-copy" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <h2 className="text-headline-sm">Advanced Settings</h2>
                  <div className="flex flex-nowrap gap-10 mb-7">
                    <div className="basis-6/12">
                      <p className="font-bold m-0">Organization Code (Optional)</p>
                      <p>Add an Organization Code for an extra level of security. This code must be used to access galleries on the Organization Page.</p>
                      <div className="relative mb-5">
                        <input
                          type="text"
                          name="org_page_access_code"
                          value={organizationInfo?.org_page_access_code ?? ''}
                          minLength={3}
                          maxLength={20}
                          placeholder="e.g. 00000000"
                          onChange={handleOrgInfoChange}
                        />
                        <button
                          className="button button--icon"
                          name="copy"
                          type="button"
                          disabled={!organizationInfo?.org_page_access_code}
                          onClick={() => handleTextCopy(organizationInfo?.org_page_access_code || '')}
                        >
                          <i className="icon-copy" />
                        </button>
                      </div>
                    </div>
                    <div className="basis-6/12">
                      <div className="mb-2.5">
                        <input
                          id="visible"
                          className="hidden"
                          name="auto_show_new_jobs_on_org_page"
                          type="checkbox"
                          checked={!!organizationInfo?.auto_show_new_jobs_on_org_page}
                          onChange={handleOrgInfoChange}
                        />
                        <label className="label--checkbox text-body-md font-bold" htmlFor="visible">
                          Display New Jobs on the Organization Page by Default*
                        </label>
                        <small className="text-body-xs italic">*These settings will not affect pre-existing jobs</small>
                      </div>
                      <div className={organizationInfo?.auto_show_new_jobs_on_org_page ? '' : 'disabled'}>
                        <p>
                          When selected, newly created jobs in AdvancePay and Published statuses will be visible on the Organization Page by default. Select the
                          additional gallery statuses you want to display.
                        </p>
                        <div className="mb-5 pl-5">
                          <input
                            id="draft"
                            className="hidden"
                            type="checkbox"
                            name="auto_show_draft_on_org_page"
                            checked={!!organizationInfo?.auto_show_draft_on_org_page}
                            onChange={handleOrgInfoChange}
                          />
                          <label htmlFor="draft" className="label--checkbox">
                            Draft
                          </label>
                          <input
                            id="expired"
                            className="hidden"
                            type="checkbox"
                            name="auto_show_expired_on_org_page"
                            checked={!!organizationInfo?.auto_show_expired_on_org_page}
                            onChange={handleOrgInfoChange}
                          />
                          <label htmlFor="expired" className="label--checkbox">
                            Expired
                          </label>
                        </div>
                      </div>
                      <aside className="flex items-center nowrap">
                        <i className="icon-lamp basis-1/12" />
                        <small className="italic">
                          You can change the visibility of new and existing jobs on your Organization Page from an individual job’s{' '}
                          <b>Settings &gt; Gallery Access</b>.
                        </small>
                      </aside>
                    </div>
                  </div>
                  {isGroupGalleriesFlagEnabled && (
                    <div className="basis-6/12">
                      <div className="flex items-center gap-1 mb-2.5">
                        <p className="font-bold m-0">Secure Group Galleries on Organization Page </p>
                        <Tooltip
                          {...{
                            html: (
                              <p className="text-left">
                                A reminder about gallery access: <br />
                                • Private = Individual gallery access codes (or Gallery Lookup if enabled) <br />
                                • Group = One gallery access code <br />• Public = No gallery access code
                              </p>
                            )
                          }}
                        >
                          <i className="icon-question-mark"></i>
                        </Tooltip>
                      </div>
                      <p>
                        Select how customers access group galleries on this Organization Page.
                        <br />
                        <strong>• Access Code Required</strong> - Customers must enter the group gallery's access code.
                        <br />
                        <strong>• Access Code Not Required</strong> - All customers with access to the Organization Page can conveniently view group galleries
                        without entering that gallery's access code.
                      </p>
                      <div className="flex items-center mb-2.5">
                        <input
                          id="secureGroupGalleriesRequired"
                          type="radio"
                          name="org_page_secure_group_galleries"
                          value="true"
                          checked={organizationInfo?.org_page_secure_group_galleries === true}
                          onChange={handleOrgInfoChange}
                        />
                        <label htmlFor="secureGroupGalleriesRequired" className="label--clean text--normal capitalize">
                          Access Code Required
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="secureGroupGalleriesNotRequired"
                          type="radio"
                          name="org_page_secure_group_galleries"
                          value="false"
                          checked={organizationInfo?.org_page_secure_group_galleries === false}
                          onChange={handleOrgInfoChange}
                        />
                        <label htmlFor="secureGroupGalleriesNotRequired" className="label--clean text--normal capitalize">
                          Access Code Not Required
                        </label>
                      </div>
                    </div>
                  )}
                </fieldset>
                <footer className="flex justify-end">
                  <button
                    className="button"
                    type="button"
                    name="save"
                    data-loading={requesting}
                    disabled={didOrganizationChange || (organizationInfo?.org_page_enabled && !organizationInfo?.org_page_slug)}
                    onClick={handleOrgPageSave}
                  >
                    Save
                  </button>
                </footer>
              </form>
            </>
          )}
        </section>
      </main>

      {showLogoErrorModal && <LogoErrorModal logoErrorMessage={logoErrorMessage} onModalClose={handleLogoErrorClose} />}

      {showAddEditModal && (
        <AddEditModal
          organizationId={organizationId}
          organizationName={organization?.name}
          organizationReportingCode={organization?.reporting_code}
          organizationNotes={organization?.notes}
          onModalClose={handleAddEditModalClose}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          organizationId={organizationId}
          organizationName={organization?.name}
          onOrganizationDelete={handleOrganizationDeleteConfirm}
          onModalClose={handleDeleteModalClose}
        />
      )}
      {showAddEditContactModal && (
        <AddEditContactModal organizationId={organizationId} contactId={selectedContactId} onModalClose={handleAddEditContactModalClose} />
      )}
      {showDeleteContactModal && (
        <DeleteContactModal organizationId={organizationId} contactId={selectedContactId} onModalClose={handleDeleteContactModalClose} />
      )}
    </>
  );
};

export default Details;
