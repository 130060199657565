import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getMyAccountStudioRequest, getMyAccountStudioTransactionsRequest } from '../../actions';

// Plugins
import { Tooltip } from 'react-tippy';
import Select, { SingleValue } from 'react-select';
import DatePicker from 'react-datepicker';

// Components
import BuyWalletCredits from './BuyWalletCredits';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Helpers
import { pickerDate, shortDate } from '@/utils/displayFormats';
import { getWalletCreditValue } from '@/utils/getWalletCreditValue';

// Types
import { WalletServiceType, WalletTransaction, WalletTransactionType } from '@/types';

interface TableFilter {
  serviceType: SingleValue<any> | null;
  startDate?: Date;
  endDate?: Date;
  perPage: number | null;
  page: number | null;
}

const serviceTypeOptions: SingleValue<any> = [
  { value: WalletServiceType.Extraction, label: 'Knockout' },
  { value: WalletServiceType.ColorCorrection, label: 'Color Correction' },
  { value: WalletServiceType.CreditPurchase, label: 'Credit Purchase' }
];

// Helper array for the types of transactions that show in the 'Credits Deducted' section
const walletTransactionDeductionTypes = [WalletTransactionType.CreditSpend, WalletTransactionType.CreditDeduction];

// Helper array for the types of transactions that show in the 'Credits Added' section
const walletTransactionAdditionTypes = [WalletTransactionType.CreditPurchase, WalletTransactionType.CreditRefund, WalletTransactionType.CreditRebate];

const WalletCredits: FC = () => {
  const dispatch = useDispatch();

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const {
    studio: usersStudio,
    studioWalletTransactions: { summary: walletTransactionsSummary, list: walletTransactionsList, pagination: walletTransactionsPagination },
    requesting
  } = useSelector((state: any) => state.users);
  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const [showBuyWalletCreditsModal, setShowBuyWalletCreditsModal] = useState<boolean>(false);

  const [tableFilter, setTableFilter] = useState<TableFilter>({
    serviceType: null,
    startDate: undefined,
    endDate: undefined,
    perPage: 20,
    page: 1
  });

  // Helper functions
  const fetchTransactions = ({ studioId, tableFilter, exportCsv }: { studioId: string; tableFilter?: TableFilter; exportCsv?: boolean }): void => {
    dispatch(
      getMyAccountStudioTransactionsRequest({
        studioId,
        ...(exportCsv ? { format: 'csv', fileName: `${studio?.name} - Wallet Transactions ${pickerDate(new Date())}.csv` } : {}),
        ...(tableFilter?.serviceType ? { service_type: tableFilter.serviceType?.value } : {}),
        ...(tableFilter?.startDate ? { start_date: pickerDate(tableFilter.startDate) } : {}),
        ...(tableFilter?.endDate ? { end_date: pickerDate(tableFilter.endDate) } : {}),
        ...(tableFilter?.perPage ? { per_page: tableFilter.perPage } : {}),
        ...(tableFilter?.page ? { page: tableFilter.page } : {})
      })
    );
  };

  // UI Handlers
  const handleFilterChange = ({ key, value }: { key: keyof TableFilter; value: Date | SingleValue<any> }): void => {
    setTableFilter((prevTableFilter) => ({ ...prevTableFilter, [key]: value }));
  };

  const handleFilter = (): void => fetchTransactions({ studioId: studio?.id, tableFilter: { ...tableFilter, page: null } });

  const handleExport = (): void => fetchTransactions({ studioId: studio?.id, tableFilter: { ...tableFilter, page: null, perPage: null }, exportCsv: true });

  const handlePagination = (page: number = 0): void => {
    setTableFilter((prevTableFilter) => ({ ...prevTableFilter, page }));
    fetchTransactions({ studioId: studio?.id, tableFilter: { ...tableFilter, page } });
  };

  useEffect(() => {
    dispatch(
      getMyAccountStudioRequest({ studioId: studio?.id }, () => {
        fetchTransactions({ studioId: studio?.id, tableFilter });
      })
    );
  }, []);

  return (
    <>
      <header className="flex items-center my-studio__header">
        <h2 className="text-headline-sm">Wallet</h2>
      </header>

      <section className="flex items-center justify-between center-sm gap-2.5 panel panel--section mb-10">
        <p className="m-0">
          You have <b>{getWalletCreditValue(studio.credit_cents).toLocaleString()}</b> credits available.
        </p>
        <button className="button button--medium" name="buy-credits" type="button" onClick={() => setShowBuyWalletCreditsModal(true)}>
          Buy Credits
        </button>
      </section>

      <h4 className="mb-5">Transaction History</h4>

      {/* Filter */}
      <aside className="flex justify-between mb-5">
        <div className="flex items-center button-group">
          <DatePicker
            className="input--date"
            selected={tableFilter.startDate}
            selectsStart
            startDate={tableFilter.startDate}
            endDate={tableFilter.endDate}
            maxDate={new Date()}
            isClearable={true}
            strictParsing
            placeholderText="MM/DD/YYYY"
            onChange={(value) => handleFilterChange({ key: 'startDate', value })}
          />
          <i className="icon-arrow-long-right"></i>
          <DatePicker
            className="input--date"
            selected={tableFilter.endDate}
            selectsEnd
            startDate={tableFilter.startDate}
            endDate={tableFilter.endDate}
            minDate={tableFilter.startDate}
            maxDate={new Date()}
            isClearable={true}
            strictParsing
            placeholderText="MM/DD/YYYY"
            onChange={(value) => handleFilterChange({ key: 'endDate', value })}
          />
          <Select
            className="basis-2/6 select"
            classNamePrefix="select"
            value={tableFilter.serviceType}
            isClearable={true}
            placeholder="Select Service Type"
            isLoading={requesting}
            options={serviceTypeOptions}
            onChange={(value) => handleFilterChange({ key: 'serviceType', value })}
          />
          <button className="button" name="filter" type="button" onClick={handleFilter} disabled={requesting}>
            Filter
          </button>
        </div>
        <Tooltip {...{ title: 'Download CSV', position: 'top', arrow: true }}>
          <button className="button button--outline button--small" name="export" type="button" disabled={requesting} onClick={handleExport}>
            <i className="icon-download-sharp"></i>
          </button>
        </Tooltip>
      </aside>

      {/* Table */}
      <article className="table-box">
        <table className="table">
          <thead className="table__header">
            <tr>
              <th>Date</th>
              <th>Service</th>
              <th>Job Name</th>
              <th>Service Name</th>
              <th>Credits Deducted</th>
              <th>Credits Added</th>
              <th>Amount Paid</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {requesting ? (
              <tr>
                <td colSpan={7}>
                  <TableLoader rows={6} rowHeight={30} />
                </td>
              </tr>
            ) : walletTransactionsList?.length ? (
              <>
                {walletTransactionsList.map((item: WalletTransaction) => (
                  <tr className="animate" key={item.id}>
                    <td data-header="Date">{item.created_at ? shortDate(item.created_at) : '-'}</td>
                    <td className="capitalize" data-header="Service">
                      {/* Deduction and refund don't have the historical information on the server so we put these in manually for now */}
                      {item.key === WalletTransactionType.CreditDeduction
                        ? 'Credit Deduction'
                        : item.key === WalletTransactionType.CreditRefund
                          ? 'Credit Addition'
                          : (item.parameters?.service_type ?? '-')}
                    </td>
                    <td className="table-dynamic__cell truncate capitalize" data-header="Job Name">
                      {item.parameters?.job_id ? <Link to={`/jobs/${item.parameters.job_id}/services/postprocessing`}>{item.parameters.job_name}</Link> : '-'}
                    </td>
                    <td className="table-dynamic__cell truncate capitalize" data-header="Service Name">
                      {item.parameters?.service_name ?? '-'}
                    </td>
                    <td data-header="Credits Used">
                      {walletTransactionDeductionTypes.includes(item.key) ? getWalletCreditValue(item.parameters?.amount).toLocaleString() : '-'}
                    </td>
                    <td data-header="Credits Added">
                      {walletTransactionAdditionTypes.includes(item.key) ? getWalletCreditValue(item.parameters?.amount).toLocaleString() : '-'}
                    </td>
                    <td data-header="Amount Paid">
                      {item.key === WalletTransactionType.CreditPurchase
                        ? Number(item.parameters?.amount / 100).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD'
                          })
                        : '-'}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr className="text-center">
                <td colSpan={6}>No transactions were found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </article>
      {walletTransactionsPagination.total > walletTransactionsPagination.perPage && (
        <Pagination pagination={walletTransactionsPagination} onPagination={handlePagination} showPagesCount={4} />
      )}

      {/* Summary */}
      <ul className="panel flex text-center justify-between">
        <li>
          <h3>{walletTransactionsSummary.total_credits_purchased?.toLocaleString()}</h3>Credits Purchased
        </li>
        <li>
          <h3>{Number(walletTransactionsSummary.total_credits_cents_purchased / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h3>Total
          Dollars Paid
        </li>
        <li>
          <h3>{walletTransactionsSummary.total_credits_used?.toLocaleString()}</h3>Total Credits Used
        </li>
        <li>
          <h3>{walletTransactionsSummary.color_correction_total?.toLocaleString()}</h3>Total Credits Used for Color Correction
        </li>
        <li>
          <h3>{walletTransactionsSummary.extraction_total?.toLocaleString()}</h3>Total Credits Used for Knockout
        </li>
      </ul>

      {showBuyWalletCreditsModal && <BuyWalletCredits studio={studio} onBuyWalletCreditsCreateModalClose={() => setShowBuyWalletCreditsModal(false)} />}
    </>
  );
};

export default WalletCredits;
