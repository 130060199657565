import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment';

// Redux
import { connect } from 'react-redux';
import { createGiftCardResendReceipt, getGiftCardTransactionsList, giftCardDetailRequest } from './actions';
import { getMyAccountStudioRequest } from '../../../Settings/actions';

// Components
import SafeLink from '@/components/Shared/Link';
import TextLoader from '@/components/Shared/ContentLoader/TextLoader';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Helpers
import { giftCardIdSelector } from './selectors';
import { convertToText } from '@/utils/currency';

// Images
import ReactImageArrowBack from '@/assets/images/icon-arrow-back.svg?react';

// Styles
import './style.css';

const mapStateToProps = (state, ownProps) => {
  const giftCardId = giftCardIdSelector(ownProps);
  const {
    users: { studio },
    login: {
      studio: { id: studioId }
    }
  } = state;

  const giftCard = state?.credits?.entities?.gift_card;
  const giftCardTransactions = state.credits?.entities?.transactions || [];
  const fromInsights = ownProps.location?.search?.includes('from_insights');
  const fromReports = ownProps.location?.state ? ownProps.location.state?.fromReports : '';

  return {
    studio,
    giftCard,
    giftCardTransactions,
    studioId,
    giftCardId,
    fromReports,
    fromInsights,
    requesting: state.credits.requesting
  };
};

const mapDispatchToProps = {
  createGiftCardResendReceipt,
  getGiftCardTransactionsList,
  giftCardDetailRequest,
  getMyAccountStudioRequest
};

const CreditDetail = ({
  giftCard = {},
  giftCardTransactions,
  requesting,
  fromReports,
  fromInsights,
  location,
  giftCardDetailRequest,
  getMyAccountStudioRequest,
  getGiftCardTransactionsList,
  studioId,
  giftCardId,
  createGiftCardResendReceipt
}) => {
  const handleResendReceipt = () => {
    createGiftCardResendReceipt({ giftCardId });
  };

  const insightsUrl = `/jobs/${giftCard.job_id}/insights/sales`;

  useEffect(() => {
    giftCardDetailRequest(giftCardId);
    getGiftCardTransactionsList({ giftCardId });

    if (studioId !== 'all-studios') {
      getMyAccountStudioRequest({ studioId });
    }
  }, []);

  return (
    <>
      <header className="credit-details__header mb-5">
        {fromInsights && (
          <SafeLink Component={Link} to={insightsUrl} disabled={requesting}>
            Back to Insights
          </SafeLink>
        )}
        {fromReports && (
          <SafeLink Component={Link} to={{ pathname: fromReports, state: { ...location.state } }} disabled={requesting}>
            Back to Reports
          </SafeLink>
        )}
        {!fromInsights && !fromReports && (
          <SafeLink Component={Link} to="/storefront/orders" disabled={requesting}>
            <ReactImageArrowBack width="12" /> View all orders
          </SafeLink>
        )}
      </header>

      <main className="credit-details__main flex flex-nowrap md:wrap gap-2.5">
        <aside className="credit-details__order panel panel--nomargin basis-3/12 md:basis-full">
          <h4>Order Number - {giftCard.num}</h4>
          <dl className="dl--inline mb-2.5">
            <dt>Order Date:</dt>
            <dd>{requesting ? <TextLoader height={20} /> : moment(giftCard.created_at).format('L')}</dd>
            <br />
            <dt>Order Status:</dt>
            <dd>{requesting ? <TextLoader height={20} /> : 'Paid'}</dd>
            {giftCard.expiration && (
              <>
                <br />
                <dt>Expiration Date:</dt>
                <dd>{requesting ? <TextLoader height={20} /> : moment(giftCard.expiration).format('L')}</dd>
              </>
            )}
          </dl>
          <aside className="mb-5">
            <div className="flex flex-nowrap gap-2.5 mb-2.5">
              <Link
                className="button button--outline button--small basis-6/12"
                to={{
                  pathname: '/support/refund-request',
                  search: `?customer_name=${`${giftCard?.customer?.first_name}${giftCard?.customer?.last_name}`}&customer_email=${
                    giftCard?.customer?.email
                  }&order_number=${giftCard.num}&purchase_type=advance_pay&refund_amount=${giftCard?.amount_cents}`
                }}
                disabled={requesting}
              >
                Request Refund
              </Link>
              <button
                className="button button--outline button--small basis-6/12"
                name="resend"
                type="button"
                onClick={handleResendReceipt}
                disabled={requesting}
              >
                Resend Receipt
              </button>
            </div>
            <button className="button button--block" name="print" type="button" onClick={window.print} disabled={requesting}>
              Print Invoice
            </button>
          </aside>

          <h4>Customer Information</h4>
          <dl className="mb-2.5">
            <dt>Bill to:</dt>
            {requesting || !giftCard.address ? (
              <TextLoader height={40} />
            ) : (
              <dd>
                {giftCard.address.recipient}
                <br />
                {giftCard.address.line1}
                {giftCard.address.line2}
                <br />
                {giftCard.address.city}, {giftCard.address.state} {giftCard.address.zip}
              </dd>
            )}
          </dl>
          <dl className="mb-5">
            <dt>Additional Info:</dt>
            {requesting || !giftCard.customer ? (
              <TextLoader height={40} />
            ) : (
              <dd className="text--normalized">
                {giftCard.customer.first_name} {giftCard.customer.last_name}
                <br />
                {giftCard.email}
                <br />
                {giftCard.phone}
              </dd>
            )}
          </dl>

          <h4>Additional Information</h4>
          <dl className="dl--inline mb-5">
            <dt>Job Name:</dt>
            <dd>{requesting ? <TextLoader height={20} /> : <Link to={`/jobs/${giftCard.job_id}/dashboard`}>{giftCard.job_name}</Link>}</dd>
            <br />
            <dt>Job Access Mode:</dt>
            <dd>
              {requesting ? (
                <TextLoader height={20} />
              ) : (
                { access_per_subject: 'Private', access_public: 'Public', access_per_job: 'Group' }[giftCard.job_access_mode]
              )}
            </dd>
            {giftCard.job_access_mode !== 'access_per_subject' && (
              <>
                <br />
                <dt>Job Access Code:</dt>
                <dd>{requesting ? <TextLoader height={20} /> : giftCard.job_access_code}</dd>
              </>
            )}
            <br />
            <dt>Studio Name:</dt>
            <dd>{requesting ? <TextLoader height={20} /> : giftCard.studio_name}</dd>
            {giftCard.subject_details && (
              <>
                <br />
                <dt>Subject Info:</dt>
                <dd>{requesting ? <TextLoader height={20} /> : giftCard.subject_details}</dd>
              </>
            )}
          </dl>

          <footer className="panel panel--lean panel--dark flex justify-between">
            <h4 className="m-0">Total</h4>
            <h4 className="m-0">{requesting ? <TextLoader height={20} /> : convertToText(giftCard.amount_cents, '$')}</h4>
          </footer>
        </aside>
        <article className="credit-details__transactions basis-9/12 md:basis-full">
          {moment(giftCard.expiration).isBefore(moment().subtract(1, 'days')) === true && (
            <aside className="panel panel--danger panel--lean panel--round text-center">
              <h4 className="m-0 text-white-base">This AdvancePay credit has expired with {convertToText(giftCard.remaining_amount_cents, '$')} remaining</h4>
            </aside>
          )}

          <table className="table">
            <caption>
              <hgroup className="flex justify-between">
                <h3>Transaction History</h3>
                {giftCard.expiration && <h3>Expiration Date: {moment(giftCard.expiration).format('L')}</h3>}
              </hgroup>
            </caption>
            <thead className="table__header">
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>ID</th>
                <th>Amount</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {requesting ? (
                <tr>
                  <td colSpan="5">
                    <TableLoader rows={5} />
                  </td>
                </tr>
              ) : (
                <>
                  {giftCardTransactions.map((transaction) => (
                    <tr key={transaction.transaction_id}>
                      <td data-header="Date">{moment(transaction.transaction_date).format('L')}</td>
                      <td data-header="Type">{transaction.transaction_type}</td>
                      <td data-header="Id">
                        {transaction.transaction_type === 'AP' ? (
                          <b>{transaction.transaction_id}</b>
                        ) : (
                          <Link to={`/storefront/orders/${transaction.order_id}`}>
                            <b>{transaction.transaction_id}</b>
                          </Link>
                        )}
                      </td>
                      <td data-header="Amount">
                        {Number(transaction.transaction_amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                      </td>
                      <td data-header="Balance">
                        {Number(transaction.remaining_balance / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
            <tfoot className="table__footer">
              <tr>
                <td colSpan="4">
                  <h3 className="m-0">Remaining Balance</h3>
                </td>
                <td>
                  <h3 className="m-0">
                    {Number(giftCard.remaining_amount_cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ?? '-'}
                  </h3>
                </td>
              </tr>
            </tfoot>
          </table>
        </article>
      </main>
    </>
  );
};

CreditDetail.propTypes = {
  giftCard: object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditDetail);
